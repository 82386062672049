import Bugsnag from '@bugsnag/js';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import type { AppProps } from 'next/app';
import React from 'react';
//import 'rsuite/dist/styles/rsuite-default.css';
import 'tailwindcss/tailwind.css';
import Archimedes from '../client_utils/archimedes';
import LogCatcher from "../client_utils/log-catcher";
import theme from '../client_utils/theme';
import '../styles/globals.css';
import '../styles/roboto.css';
import '../utils/init-auth';
import bugsnagInitialized from '../utils/init-bugsnag';

function MyApp({ Component, pageProps }: AppProps) {
  React.useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles && jssStyles.parentElement) {
      jssStyles.parentElement.removeChild(jssStyles);
    }

    // Start Archimedes
    Archimedes.instance().initialize();
    LogCatcher.instance();

    // Test bugsnag
    // Bugsnag.notify(new Error('Test error'))
  }, []);

  let appWithoutErrorBoundary = (
    <ThemeProvider theme={theme}>
      {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
      <CssBaseline />
      <Component {...pageProps} />
    </ThemeProvider>
  );

  if (bugsnagInitialized) {
    // @ts-ignore
    const ErrorBoundary = Bugsnag.getPlugin('react')
      .createErrorBoundary(React)

    return (
      <ErrorBoundary>
        {appWithoutErrorBoundary}
      </ErrorBoundary>
    );
  } else {
    return appWithoutErrorBoundary;
  }
}
export default MyApp
