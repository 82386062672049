import FingerprintJS from "@fingerprintjs/fingerprintjs";
import axios from "axios";

export default class Archimedes {
  sessionId: string | null = null;
  isBounce: boolean = true;
  pageHistogram = new Map<string, number>();
  eventHistogram = new Map<string, number>();

  static instance(): Archimedes {
    if (this._instance) {
      return this._instance;
    }
    this._instance = new Archimedes();
    return this._instance;
  }

  constructor() {
    this.pageHistogram.set("/", 1);
  }

  async initialize() {
    if (
      process.env.JEST_WORKER_ID ||
      process.env.NEXT_PUBLIC_WEBPACK_BUILD_ID == "development"
    ) {
      // Reporting disabled
      return;
    }

    const {
      screen: { width, height },
      navigator: { language, userAgent },
      location: { hostname, pathname, search },
      localStorage,
      sessionStorage,
      document,
      history,
    } = window;

    let fpAgent = await FingerprintJS.load();
    let visitorFingerprint = (await fpAgent.get()).visitorId;

    let sessionStart = {
      language,
      userAgent,
      referrer: document.referrer,
      visitorFingerprint,
      nextJsCommit: process.env.NEXT_PUBLIC_WEBPACK_BUILD_ID,
    };
    console.log("SESSION START: " + JSON.stringify(sessionStart));
    let sessionStartResponse = await axios.post(
      "/api/archimedes/session-begin",
      sessionStart
    );
    if (sessionStartResponse.status == 204) {
      // Reporting disabled
      return;
    }
    if (sessionStartResponse.status != 200) {
      console.error(
        "Invalid session start response: " +
          JSON.stringify(sessionStartResponse)
      );
      return;
    }
    this.sessionId = sessionStartResponse.data.sessionId;

    document.addEventListener("visibilitychange", () => {
      if (document.visibilityState === "hidden") {
        navigator.sendBeacon(
          "/api/archimedes/session-end/" + this.sessionId,
          JSON.stringify(this.getSessionEnd())
        );
      }
    });
  }

  getSessionEnd() {
    let sessionEnd = {
      bounce: this.isBounce,
      pageHistogram: Object.fromEntries(this.pageHistogram),
      eventHistogram: Object.fromEntries(this.eventHistogram),
    };
    this.pageHistogram.clear();
    this.eventHistogram.clear();
    return sessionEnd;
  }

  static visitPage(name: string) {
    let arch = Archimedes.instance();
    let currentCount = arch.pageHistogram.get(name) || 0;
    arch.pageHistogram.set(name, currentCount + 1);
  }

  static event(name: string) {
    let arch = Archimedes.instance();
    let currentCount = arch.eventHistogram.get(name) || 0;
    arch.eventHistogram.set(name, currentCount + 1);
  }

  static _instance: Archimedes;
}
