import axios from "axios";
import { RingBuffer } from "ring-buffer-ts";
import Archimedes from "./archimedes";

function _stringify_and_prune(args: any[]) {
  try {
    let result = JSON.stringify(args);
    if (result.length < 1024) {
      return args;
    }
    for (var a = args.length - 2; a >= 1; a--) {
      let reducedArgs = ["(Truncated)"].concat(args.slice(0, a));
      let truncatedResult = JSON.stringify(reducedArgs);
      if (truncatedResult.length < 1024) {
        return ["(Truncated)"].concat(args.slice(0, a));
      }
    }
    return ["(Log too long)"];
  } catch (err: any) {
    return [err.toString()];
  }
}

export default class LogCatcher {
  static _instance: LogCatcher;

  static instance(): LogCatcher {
    if (LogCatcher._instance) {
      return LogCatcher._instance;
    }
    LogCatcher._instance = new LogCatcher();
    return this._instance;
  }

  logBuffer = new RingBuffer<any[]>(1024);
  reportedError = false;
  reportedLogError = false;

  constructor() {
    let oldThis = this;
    // define a new console
    let newConsole = (function (oldCons) {
      return {
        ...oldCons,
        log: function (...args: any[]) {
          oldCons.log(...args);
          // Your code
          oldThis.logBuffer.add(_stringify_and_prune(["log"].concat(args)));
        },
        info: function (...args: any[]) {
          oldCons.info(...args);
          // Your code
          oldThis.logBuffer.add(_stringify_and_prune(["info"].concat(args)));
        },
        warn: function (...args: any[]) {
          oldCons.warn(...args);
          // Your code
          oldThis.logBuffer.add(_stringify_and_prune(["warn"].concat(args)));
        },
        error: function (...args: any[]) {
          oldCons.error(...args);
          if (
            args.length > 0 &&
            (args[0] as string).search("Legacy context API") >= 0
          ) {
            return;
          }
          // Your code
          oldThis.logBuffer.add(_stringify_and_prune(["error"].concat(args)));
          if (!oldThis.reportedLogError) {
            oldThis.reportedLogError = true;
            if (Archimedes.instance().sessionId == null) {
              return;
            }

            let payload = {
              userAgent: navigator.userAgent,
              logHistory: oldThis.logBuffer.toArray(),
            };

            axios.post("/api/archimedes/browser-error-catcher", payload);
            console.warn("Reporting error");
          }
        },
      };
    })(window.console);

    //Then redefine the old console
    window.console = newConsole;

    window.onerror = function (
      event: Event | string,
      source?: string,
      lineno?: number,
      colno?: number,
      error?: Error
    ) {
      if (oldThis.reportedError) {
        return false;
      }
      oldThis.reportedError = true;
      if (Archimedes.instance().sessionId == null) {
        return false;
      }

      let payload = {
        event,
        source,
        lineno,
        colno,
        error,
        userAgent: navigator.userAgent,
        logHistory: oldThis.logBuffer.toArray(),
      };

      axios.post("/api/archimedes/browser-crash-catcher", payload);
      console.warn("Reporting crash");

      return false;
    };
  }
}
