import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";

var bugsnagInitialized = false;

if (
  // @ts-ignore
  !Bugsnag._client &&
  process.env.JEST_WORKER_ID === undefined &&
  process.env.NEXT_PUBLIC_WEBPACK_BUILD_ID != "development"
) {
  bugsnagInitialized = true;
  console.log("STARTING BUGSNAG (CLIENT)");
  if (typeof window === "undefined") {
    // Server-side
    Bugsnag.start({
      apiKey: "a341e4d096c18128e984bd33d3ece25e",
      plugins: [new BugsnagPluginReact()],
    });
  } else {
    // Client-side
    Bugsnag.start({
      apiKey: "c4298c50ee941d9ccf684510723fccf1",
      plugins: [new BugsnagPluginReact()],
    });
  }
}

export default bugsnagInitialized;
